import React, { useState, useEffect } from 'react';
import { fetchFile } from '../../Helpers/Requests';
import { can } from '../../Helpers/Helpers';
import NoPermission from '../ErrorPages/NoPermission';
import { PERMISSIONS, userData } from '../../Helpers/Constants';

export default function FileViewer() {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  useEffect(() => {
    // get the file path which is the part after files
    const filePath = window.location.pathname.split('/files/')[1];
    const fetchData = async () => {
      try {
        const fileData = await getFileFromApi(filePath);
        setFile(fileData);
        const extension = getFileExtension(filePath);
        setFileType(extension === 'pdf' ? 'pdf' : 'image');
      } catch (error) {
        console.error('Error fetching file from API', error);
      }
    };
    if (filePath !== undefined) {
      fetchData();
    }
  }, []);

  const getFileFromApi = async (filePath) => {
    try {
      return await fetchFile(filePath);
    } catch (error) {
      console.error('Error fetching file from API', error);
      throw error;
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

  return (
    <>
      {!can([PERMISSIONS.ManageUsers]) &&
      !window.location.href
        .toLowerCase()
        .includes(userData.email.toLowerCase()) ? (
        <NoPermission />
      ) : (
        <>
          {fileType && file && (
            <div className="d-flex justify-content-center">
              {fileType === 'pdf' && (
                <iframe
                  title="PDF Viewer"
                  src={file}
                  width="100%"
                  style={{ height: '40rem' }}
                >
                  <p>Your browser does not support iframes.</p>
                </iframe>
              )}
              {fileType !== 'pdf' && (
                <img src={file} alt="File" style={{ height: '40rem' }} />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
