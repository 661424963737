import React, { useState } from 'react';
import * as Requests from '../../../Helpers/Requests';
import Header from '../../Layout/Header';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function getEmail(e) {
    setEmail(e.target.value);
  }

  async function formSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    Requests.formRequest('password/forgot', 'post', { email })
      .then((response) => {
        setSuccessMessage('Password reset link sent to your email.');
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error.response);

        setErrMessage(error.response.data.error ?? error.response.data.message);
        setIsSubmitting(false);
      });
  }

  return (
    <div style={{ height: '80vh' }}>
      <Header />
      <div className="container d-flex justify-content-center align-items-center h-100 flex-column">
        <div className="d-flex justify-content-center h1 mb-5">
          Password Reset Request
        </div>
        <form className="w-50" onSubmit={formSubmit}>
          <div
            className="d-flex justify-content-center h5 mb-4"
            style={{ color: '#505050' }}
          >
            Enter your email address and we will send you a link to reset your
            password
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Your Email
            </label>
            <input
              onChange={getEmail}
              type="email"
              className="form-control"
              name="email"
            ></input>
          </div>
          <div className="d-flex d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Password Reset Email'}
            </button>
          </div>
          {errMessage && (
            <div className="form-group">
              <div className="alert alert-danger mt-3" role="alert">
                {errMessage}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="form-group">
              <div className="alert alert-success mt-3" role="alert">
                {successMessage}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
