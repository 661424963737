import React from 'react';
import { useLocation } from 'react-router-dom';
import { Sidebar, Menu } from 'react-pro-sidebar';

import './style.css';
import { sidebarWidth, userData } from '../../../Helpers/Constants';
import { MenuItemStyles, SidebarMenuElements } from './consts';
import { findSubMenuKey, renderNavItems } from './utils';

export default function NavSidebar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const CurrentSubmenusIds = findSubMenuKey(SidebarMenuElements, currentPath);

  return (
    <div style={{ width: sidebarWidth }} className="sidebar-wrapper">
      <Sidebar style={{ width: sidebarWidth }}>
        <div className="flex items-center justify-center text-center pt-4 pb-2">
          <span className="mx-2 text-2xl font-semibold">{userData.name}</span>
        </div>
        <div className="flex items-center justify-center text-center pb-4">
          <span className="mx-2 text-2xl font-semibold">{userData.title}</span>
        </div>

        <Menu menuItemStyles={MenuItemStyles}>
          {renderNavItems(SidebarMenuElements, CurrentSubmenusIds)}
        </Menu>
      </Sidebar>
    </div>
  );
}
