import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Requests from '../../../Helpers/Requests';
import Header from '../../Layout/Header';

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handlePasswordConfirmationChange(e) {
    setPasswordConfirmation(e.target.value);
  }

  async function formSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    Requests.formRequest('password/reset', 'post', {
      token,
      email,
      password,
      password_confirmation: passwordConfirmation,
    })
      .then((response) => {
        setSuccessMessage('Password reset successfully.');
        setIsSubmitting(false);
        setTimeout(() => navigate('/login'), 3000);
      })
      .catch((error) => {
        setErrMessage(error.response.data.error ?? error.response.data.message);
        setIsSubmitting(false);
      });
  }

  return (
    <div style={{ height: '80vh' }}>
      <Header />
      <div className="container d-flex justify-content-center align-items-center h-100 flex-column">
        <div className="d-flex justify-content-center h1 mb-5">
          Reset Password
        </div>
        <form className="w-50" onSubmit={formSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Your Email
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              disabled
            ></input>
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              New Password
            </label>
            <input
              onChange={handlePasswordChange}
              type="password"
              className="form-control"
              name="password"
            ></input>
          </div>

          <div className="mb-3">
            <label htmlFor="passwordConfirmation" className="form-label">
              Confirm New Password
            </label>
            <input
              onChange={handlePasswordConfirmationChange}
              type="password"
              className="form-control"
              name="passwordConfirmation"
            ></input>
          </div>

          <div className="d-flex d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Resetting...' : 'Reset Password'}
            </button>
          </div>
          {errMessage && (
            <div className="form-group">
              <div className="alert alert-danger mt-3" role="alert">
                {errMessage}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="form-group">
              <div className="alert alert-success mt-3" role="alert">
                {successMessage}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
