import React from 'react';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as OvertimeManageUtils from './utils';
import OvertimeManageShow from './show';

import MUIDataTable from 'mui-datatables';
import useDatatable from '../../../hooks/useDatatable';

export default function OvertimeManageTable({ currentTable }) {
  const IdCellIndex = 0;

  // Requests data variables
  const [originalOvertimeRequests, setOriginalOvertimeRequests] =
    React.useState([]);
  const [datatableOvertimeRequests, setDatatableOvertimeRequests] =
    React.useState([]);
  // Requests data variables

  // Mui datatables variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  const [currentFilterFields, setCurrentFilterFields] = React.useState({});
  const [requestsCount, setRequestsCount] = React.useState(0);
  const { tableState, options } = useDatatable({
    handleFilterSubmit,
    count: requestsCount,
    onRowClick: (row) => handleManageRequestOpen(row[IdCellIndex]),
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.getOvertimeRequests(
        currentTable.type,
        null,
        null,
        currentFilterFields,
      )
        .then((overtimeRequests) => {
          const tableData = overtimeRequests.data;
          const printableTableData =
            OvertimeManageUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Overtime_Requests.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
  });
  // Mui datatables

  React.useEffect(() => {
    Requests.getOvertimeRequests(
      currentTable.type,
      tableState.page,
      tableState.rowsPerPage,
      currentFilterFields,
    )
      .then((requests) => {
        setOriginalOvertimeRequests(requests.data);
        setRequestsCount(requests.meta.total);
        setCurrentColumns(OvertimeManageUtils.getDatatableColumns());
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [
    tableState.page,
    tableState.rowsPerPage,
    currentFilterFields,
    currentTable.type,
  ]);

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _styledOvertimeRequests = OvertimeManageUtils.originalToStyled(
      originalOvertimeRequests,
    );
    const _datatableOvertimeRequests = OvertimeManageUtils.styledToMuiDatatable(
      _styledOvertimeRequests,
      handleManageRequestOpen,
    );
    setDatatableOvertimeRequests(_datatableOvertimeRequests);
  }, [originalOvertimeRequests]);
  // update styled and datatable requests whenever fetched requests change

  function handleFilterSubmit(applyFilters) {
    const filterFields = OvertimeManageUtils.createFilterFields(
      tableState,
      applyFilters,
    );

    setCurrentFilterFields(filterFields);
  }

  // Manage Overtime Request section
  const [managedRequestLoaded, setManagedRequestLoaded] = React.useState(false);
  const [openManageRequestDialog, setOpenManageRequestDialog] =
    React.useState(false);
  const [managedOvertimeRequest, setManagedOvertimeRequest] = React.useState(
    {},
  );

  const handleManageRequestOpen = (requestId) => {
    Requests.getOvertimeRequest(requestId)
      .then((overtimeRequest) => {
        setManagedOvertimeRequest(overtimeRequest);
        setManagedRequestLoaded(true);
        setOpenManageRequestDialog(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  const handleManageRequestClose = () => setOpenManageRequestDialog(false);
  // Manage Overtime Request section

  return (
    <>
      {managedRequestLoaded && (
        <OvertimeManageShow
          open={openManageRequestDialog}
          onClose={handleManageRequestClose}
          managedOvertimeRequest={managedOvertimeRequest}
          currentTable={currentTable}
        />
      )}
      <MUIDataTable
        title={currentTable.titleName}
        data={datatableOvertimeRequests}
        columns={currentColumns}
        options={options}
      />
    </>
  );
}
